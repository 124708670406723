<template>
  <div class="inline-block" style="position: relative;">
    <div class="inline-block fileXml">
      <input type="file" value="导入xlsx" accept=".xls,.xlsx" ref="refInput" class="upload_file" @change="readExcel($event)" />
      <vs-button size="small" type="relief" class="upload_btn">导入xlsx</vs-button>
    </div>
    <div class="inline-block ml-4">
      <vs-button size="small" type="relief" class="download_btn" @click="download_btn">下载模版</vs-button>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'

export default {
  name: 'vx-people-fileXml',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    peopleList: {
      type: Array,
      default: []
    }
  },
  computed: {
    uploadLoading: {
      get() {
        return this.loading
      },
      set(val) {
        this.$emit('update:loading', val)
      }
    },
    uploadPeopleList: {
      get() {
        return this.peopleList
      },
      set(val) {
        this.$emit('update:peopleList', val)
      }
    }
  },
  data() {
    return {
      sheetName: '人员信息',
      upload_file: '',
      lists: []
    }
  },
  watch: {},
  methods: {
    /**
     *  睡眠函数
     *  @param numberMillis -- 要睡眠的毫秒数
     */
    sleep(numberMillis) {
      var now = new Date()
      var exitTime = now.getTime() + numberMillis
      while (true) {
        now = new Date()
        if (now.getTime() > exitTime) return
      }
    },
    // 下载
    download_btn() {
      // console.log("下载模版")
      window.location.href = '/xlsx/人员导入模版.xlsx'
    },
    // 加载
    readExcel(e) {
      console.log('开始读取execl')
      this.uploadLoading = true
      // 读取表格文件
      let that = this
      const files = e.target.files
      if (files.length <= 0) return false
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$cFun.showNotify(this.$vs.notify, 'warning', '上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      // 更新获取文件名
      that.upload_file = files[0].name
      const fileReader = new FileReader()
      fileReader.onload = ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, { type: 'binary', cellDates: true })
          const wsnames = workbook.SheetNames

          wsnames.forEach(iname => {
            // 不是指定类型不提取
            let isExistType = iname == that.sheetName
            if (isExistType) {
              let ws = XLSX.utils.sheet_to_json(workbook.Sheets[iname]) //生成json表格内容
              // 从解析出来的数据中提取相应的数据
              new Promise((resolve, reject) => {
                let count = ws.length
                let currentIndex = 0

                ws.forEach(async item => {
                  // console.log('添加')
                  let isOk = await that.onloadDate(iname, item)
                  if (isOk) {
                    currentIndex++
                  }

                  if (currentIndex == count) {
                    // console.log('刷新')
                    this.$emit('handleDiaLogClose', true)
                  }
                })

                resolve()
              })
            } else {
              console.log('为检测到匹配的sheet')
            }
          })
        } catch (e) {
          console.log('解析xlsx失败:', e)
          this.$cFun.showNotify(this.$vs.notify, 'warning', '解析xlsx失败')
          return false
        }
      }

      fileReader.onerror = function(ex) {
        console.log('fileReader:', ex)
      }

      fileReader.readAsBinaryString(files[0])

      this.uploadLoading = false

      // 解决第二次同名文件上传失效问题
      this.$refs.refInput.value = null
    },
    // 处理数据
    async onloadDate(sheetName, sheetDate) {
      console.log('sheetName:', sheetName, 'sheetDate:', sheetDate)
      let obj = {
        isBind: sheetDate['是否录入'] == '未录入' ? false : true,
        name: sheetDate['姓名'],
        phone: sheetDate['手机号码'].toString(),
        documentType: sheetDate['证件类型'],
        identificationNumber: sheetDate['证件号码'].toString(),
        certificateValidity: sheetDate['证件有效期'] ? this.$cFun.formatTime(new Date(sheetDate['证件有效期']), 'yyyy-MM-dd') : this.$cFun.formatTime()
      }
      this.uploadPeopleList.push(obj)
      return true
    },
    // 获取关系内容
    async getRelation(usingTypes) {
      try {
        let params = {
          usingTypes: usingTypes
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          result.relationList.forEach(elem => {
            if (elem.using_type == `db/device/productBrand/${this.form.deviceType}`) {
              this.productBrandAndModelOptions = elem.extend
            } else if (elem.using_type == `db/device/main_type/${this.form.deviceType}`) {
              this.deviceTypeCodeOptions = elem.extend
            } else if (elem.using_type == `db/device/productBrand/${this.form.deviceType}/protocal`) {
              this.productBrandAndProtocalOptions = elem.extend
            }
          })
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取关系内容并直接返回 -> 一个一个传
    async getRelationReturnData(usingType) {
      try {
        let params = {
          usingTypes: [usingType]
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          return result.relationList[0].extend
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    }
  },
  mounted() {},
  created() {}
}
</script>

<style lang="scss">
.fileXml {
  width: 100px;
}
.fileXml .upload_file {
  position: absolute;
  width: 100px;
  height: 31px;
  top: 0;
  z-index: 1;
  opacity: 0;
}
.fileXml .upload_file:hover {
  cursor: pointer;
}
.fileXml .upload_btn {
  position: absolute;
  cursor: pointer;
  width: 100px;
  top: 0;
  left: 0;
}
</style>
