<template>
  <el-dialog customClass="people_dialog" :visible.sync="isVisible" :destroy-on-close="true" :show-close="true" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-31">{{ dialogTitle }}</h5>
    </span>

    <div>
      <el-row :gutter="24" style="padding: 10px 5px">
        <el-col :span="6">
          <h5>业主姓名: {{ peopleInfo.people_name }}</h5>
        </el-col>
        <el-col :span="9">
          <h5>业主手机号: {{ peopleInfo.mobile_phone }}</h5>
        </el-col>
        <el-col :span="9">
          <h5>业主身份证: {{ peopleInfo.certificate_no }}</h5>
        </el-col>
      </el-row>

      <vs-divider></vs-divider>

      <el-form :model="houseForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="top" v-if="reFresh">
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item label="小区选择" prop="villageCode">
              <el-select style="width:100%" v-model="villageCode" placeholder="请选择" class="w-100" @change="villageChange" :disabled="isClickHouseBtn">
                <el-option v-for="item in villageArr" :key="item.index" :label="item.space_name" :value="item.space_code" placeholder="请选择小区"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="楼栋选择" prop="building">
              <el-select style="width:100%" v-model="houseForm.building" filterable :filter-method="filterbuildingArr" placeholder="请选择楼栋" class="w-100" @change="buildingChange" :disabled="isClickHouseBtn">
                <el-option v-for="item in buildingArr" :key="item.index" :label="`${item.building_name}`" :value="item.space_code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="单元选择" prop="unit">
              <el-select style="width:100%" v-model="houseForm.unit" filterable :filter-method="filterUnitArr" placeholder="请选择单元" class="w-100" @change="unitChange" :disabled="isClickHouseBtn">
                <el-option v-for="item in unitArr" :key="item.index" :label="`${item.unit_name}`" :value="item.space_code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="房屋选择" prop="house">
              <el-select style="width:100%" v-model="houseForm.house" filterable :filter-method="filterHouseArr" placeholder="请选择房屋" class="w-100" @change="houseChange" :disabled="isClickHouseBtn">
                <el-option v-for="item in houseArr" :key="item.index" :label="item.house_no" :value="item.house_no"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="6">
            <!-- 登陆表单认证(住户来源) -->
            <el-form-item label="住户来源" prop="personType">
              <el-select style="width:100%" v-model="houseForm.personType" placeholder="请选择住户来源" class="w-100">
                <el-option v-for="item in personTypeArr" :key="item.index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 登陆表单认证(住户类型) -->
            <el-form-item label="住户类型" prop="houseUse">
              <el-select style="width:100%" v-model="houseForm.houseUse" placeholder="请选择住户类型" class="w-100">
                <el-option v-for="item in houseUseArr" :key="item.index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 弹窗内容 -->
    <span slot="footer" class="dialog-footer">
      <vs-button size="small" type="relief" @click="handleClose" style="margin-right:10px">取消</vs-button>
      <vs-button size="small" type="relief" @click="submitForm('ruleForm')">保存</vs-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['dialogFormVisible', 'dialogFormData'],
  data() {
    return {
      dialogTitle: '绑定房屋',
      isVisible: this.dialogFormVisible,
      reFresh: false,
      isClickHouseBtn: false,
      // 原始枚举
      relationReturnDataAll: [],
      CardTypeArr: [],
      houseUseArr: [],
      personTypeArr: [],
      villageArr: [],
      buildingArr: [],
      buildingArrTemp: [],
      houseArr: [],
      houseArrTemp: [],
      unitArr: [],
      unitArrTemp: [],
      //  小区信息
      villageCode: '',
      villageName: '',

      // 人员信息
      peopleInfo: {
        index: 0,
        certificate_validity: '',
        certificate_type: '',
        certificate_no: '',
        people_name: '',
        mobile_phone: ''
      },

      // 房屋表单
      houseForm: {
        ph_id: '',
        people_id: '',
        village: '',
        telephone: '',
        building: '',
        personType: 1,
        main_call: false,
        unit: '',
        unit_code: '',
        relationshipWithHouseHold: '',
        house: '',
        house_code: '',
        houseUse: '0',
        auth_start_time: new Date(),
        auth_expire_time: '2099-12-31 00:00:00'
      },

      rules: {
        village: [{ required: true, message: '请输入小区', trigger: 'blur' }],
        telephone: [{ required: false, message: '请输入固定电话', trigger: 'blur' }],
        building: [{ required: true, message: '请选择楼栋', trigger: 'change' }],
        personType: [{ required: true, message: '请选择住户来源', trigger: 'change' }],
        unit: [{ required: true, message: '请选择单元', trigger: 'change' }],
        relationshipWithHouseHold: [{ required: true, message: '请选择与户主关系', trigger: 'change' }],
        house: [{ required: true, message: '请选择房间', trigger: 'change' }],
        houseUse: [{ required: true, message: '请选择住户类型', trigger: 'change' }]
      }
    }
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      // console.log("dialogFormData", val);
      this.reFresh = false

      // 设备所需参数
      this.peopleInfo = this.decodeParam(val)

      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true
      })
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.$cFun.changeNavBarShadow(val)
      this.isVisible = this.dialogFormVisible
    },
    relationReturnDataAll(val) {
      this.politicalCodeArr = _.find(val, { using_type: 'db/people/zhengzhimianmao' }).extend
      this.educationCodeArr = _.find(val, { using_type: 'db/people/wenhuachengdu' }).extend
      this.maritalStatusCodeArr = _.find(val, { using_type: 'db/people/hunyinzhuangkuang' }).extend
      this.credentialTypeArr = _.find(val, { using_type: 'db/people/zhengjianleixing' }).extend
      this.houseUseArr = _.find(val, { using_type: 'db/people/reside_mode' }).extend
      this.personTypeArr = _.find(val, { using_type: 'db/people/person_type' }).extend
      this.cardTypeArr = _.find(val, { using_type: 'db/card/card_type' }).extend
      this.bindingTypeArr = _.find(val, { using_type: 'db/card/binding_type' }).extend
      let relationshipWithHouseHoldArr = _.find(val, { using_type: 'db/people/relation_with_house_master' }).extend
      this.relationshipWithHouseHoldArr = _.map(relationshipWithHouseHoldArr, item => {
        item.name == '户主' ? (item.disabled = true) : (item.disabled = false)
        return item
      })
    }
  },
  methods: {
    // 恢复提交参数
    decodeParam(val) {
      console.log(val)
      let peopleInfo = {
        index: val.index,
        isBind: val.isBind,
        certificate_validity: val.certificateValidity,
        certificate_type: val.documentType,
        certificate_no: val.identificationNumber,
        people_name: val.name,
        mobile_phone: val.phone
      }

      return peopleInfo
    },
    // 关闭弹框回调
    handleClose() {
      this.isShowForm = false
      this.isVisible = false
      if (this.isVisible == false) {
        this.$refs.ruleForm.resetFields()
        this.$cFun.changeNavBarShadow()
        this.$emit('handleDiaLogClose', this.peopleInfo)
      }
    },
    // 添加卡按钮
    async addCardBtn() {
      // 还原状态
      this.isShowForm = false
      this.emptyCardForm()
      // 读取卡
      let isOk = await this.readCardFlow()
      if (!isOk) return false

      // 展示卡信息
      this.isSubmitForm = true
      this.isShowForm = true
      this.isDisForm = false
    },
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.addPeopleHoouse()
        } else {
          return false
        }
      })
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el)
      }, 1000)
    },
    // 获取枚举值(全部)
    async getRelationReturnDataALL(usingTypeArr) {
      try {
        let params = {
          usingTypes: usingTypeArr
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          return result.relationList
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 获取单元列表
    async getUnitList(buildingCode) {
      try {
        let params = {
          buildingCode: buildingCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取单元列表', params)
        if (this.$apis.isOk(result)) {
          return result.data.unitList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取房间列表
    async getHouseList(unitCode) {
      try {
        let params = {
          unitCode: unitCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取房屋列表', params)
        if (this.$apis.isOk(result)) {
          return result.data.houseList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 小区被选择的时候
    async villageChange(val) {
      this.villageCode = val
      this.houseForm.building = ''
      this.houseForm.unit = ''
      this.houseForm.house = ''
      this.getBuildingList()
    },
    // 楼栋被选择的时候
    async buildingChange(val) {
      console.log('buildingChange')
      this.houseForm.unit = ''
      this.houseForm.house = ''
      this.houseArr = []
      let result = await this.getUnitList(val)
      this.unitArr = result
      this.unitArrTemp = result
    },
    // 单元
    async unitChange(val) {
      this.houseForm.house = ''
      let result = await this.getHouseList(val)
      this.houseArr = result
      this.houseArrTemp = result
    },
    // 楼栋搜索
    filterbuildingArr(val) {
      let newOriginArr = _.cloneDeep(this.buildingArrTemp)
      this.houseForm.building = val
      this.houseForm.unit = ''
      this.houseForm.house = ''
      this.houseArr = []
      this.houseArrTemp = []
      this.unitArr = []
      this.unitArrTemp = []
      if (val) {
        //val存在
        this.buildingArr = newOriginArr.filter(item => {
          if (item.building_name.includes(val)) {
            return true
          }
        })
      } else {
        this.buildingArr = this.buildingArrTemp
      }
    },
    // 单元搜索
    filterUnitArr(val) {
      let newOriginArr = _.cloneDeep(this.unitArrTemp)
      this.houseForm.unit = val
      this.houseForm.house = ''
      this.houseArr = []
      this.houseArrTemp = []
      if (val) {
        //val存在
        this.unitArr = newOriginArr.filter(item => {
          if (item.unit_name.includes(val)) {
            return true
          }
        })
      } else {
        this.unitArr = this.unitArrTemp
      }
    },
    // 房间搜索
    filterHouseArr(val) {
      let newOriginArr = _.cloneDeep(this.houseArrTemp)
      this.houseForm.house = val
      if (val) {
        //val存在
        this.houseArr = newOriginArr.filter(item => {
          if (item.house_no.includes(val)) {
            return true
          }
        })
      } else {
        this.houseArr = this.houseArrTemp
      }
    },
    // 房间
    houseChange(val) {
      let obj = _.find(this.houseArr, { house_no: val })
      this.houseForm.telephone = obj.telephone
      this.houseForm.house_code = obj.space_code
    },
    // 获取当前用户的社区
    async getVillageList() {
      try {
        let result = await this.$apis.ODC('获取用户区域权限', {})
        if (this.$apis.isOk(result)) {
          this.villageArr = result.data
        }
      } catch (err) {
        console.error(err.message ? err.message : err)
      }
    },
    // 获取楼栋列表
    async getBuildingList() {
      try {
        let params = {
          villageCode: this.villageCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取楼栋列表', params)
        if (this.$apis.isOk(result)) {
          this.buildingArr = result.data.buildingList
          this.buildingArrTemp = result.data.buildingList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 查询人员信息是否存在
    async getPeopleList(certificate_no) {
      try {
        let param = {
          certificate_no: certificate_no,
          pageindex: 1,
          pagesize: 10
        }
        let result = await this.$apis.ODC('获取住户列表', param)
        if (this.$apis.isOk(result)) {
          return result.PeopleList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
      this.loading = false
    },
    async addPeopleHoouse() {
      let peopleid = ''
      // 先查询人是否存在
      let peopleList = await this.getPeopleList(this.peopleInfo.certificate_no)
      if (peopleList.length > 0) {
        peopleid = peopleList[0].people_id
      } else {
        // 先添加人
        peopleid = await this.addPeople()
      }
      // 再添加人房关系
      let isOk = await this.addPeopleHouse(peopleid, this.peopleInfo)
      if (isOk) {
        console.log('添加成功')
        this.$cFun.showNotify(this.$vs.notify, 'success', '添加成功')
        this.peopleInfo.isBind = true
        this.handleClose()
      } else {
        console.log('添加失败')
      }
    },
    // 身份证获取年月日
    IdCard(IdCard, type) {
      if (type === 1) {
        //获取出生日期
        let birthday = IdCard.substring(6, 10) + '-' + IdCard.substring(10, 12) + '-' + IdCard.substring(12, 14)
        return birthday
      }
      if (type === 2) {
        //获取性别
        if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
          return '男'
        } else {
          return '女'
        }
      }
      if (type === 3) {
        //获取年龄
        var ageDate = new Date()
        var month = ageDate.getMonth() + 1
        var day = ageDate.getDate()
        var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
        if (IdCard.substring(10, 12) < month || (IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day)) {
          age++
        }
        if (age <= 0) {
          age = 1
        }
        return age
      }
    },
    // 保存人员信息
    async addPeople() {
      let row = this.peopleInfo
      try {
        // 证件类型
        let certificate_type = row.certificate_type == '身份证' ? '2000' : '2099'
        // 证件号码
        let certificate_no = row.certificate_no
        // 出生日期
        let birthDate = certificate_type == '2000' ? this.IdCard(certificate_no, 1) : this.$cFun.formatTime(new Date().getTime())
        // 性别
        let sexName = certificate_type == '2000' ? this.IdCard(certificate_no, 2) : '未知'
        // 提交信息
        let params = new Object()
        params['people'] = {
          certificate_no: row.certificate_no,
          people_name: row.people_name,
          mobile_phone: row.mobile_phone,
          people_type: '',
          certificate_type: certificate_type,
          certificate_validity: this.$cFun.formatTime(new Date().getTime() + 24 * 60 * 60 * 1000),
          sex: sexName == '未知' ? 99 : sexName == '男' ? 1 : 2,
          nation: '汉族',
          birth_date: birthDate,
          origin: '其他地区',
          domiclle_detail_address: '',
          domiclle_address: '',
          residence_detail_address: '',
          residence_address: '',
          education: '其他',
          political: '群众',
          marital_status: '未知',
          nationality: '中国',
          id_pic: '',
          head_fea: '',
          head_pic: '',
          live_fea: '',
          live_pic: ''
        }
        let result = await this.$apis.ODC('新增人员', params)
        return result.peopleID
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 保存人房信息
    async addPeopleHouse(people_id) {
      let row = this.houseForm
      try {
        let params = new Object()
        params = {
          unit_code: row.unit,
          house_code: row.house_code,
          house_no: row.house,
          telephone: this.peopleInfo.mobile_phone,
          people_id: people_id,
          relation_with_house_master: 99,
          person_type: row.personType,
          reside_mode: String(row.houseUse),
          auth_start_time: this.$cFun.formatTime(new Date()),
          auth_expire_time: this.$cFun.formatTime('2099-12-31 00:00:00'),
          main_call: false
        }
        let result = await this.$apis.ODC('保存人房关系', params)
        if (this.$apis.isOk(result)) {
          return true
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
          return false
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // INIT
    async init() {
      this.getVillageList()
      this.relationReturnDataAll = await this.getRelationReturnDataALL(['db/people/zhengzhimianmao', 'db/people/wenhuachengdu', 'db/people/hunyinzhuangkuang', 'db/people/zhengjianleixing', 'db/people/reside_mode', 'db/people/person_type', 'db/people/relation_with_house_master', 'db/card/card_type', 'db/card/binding_type', 'db/people/car_status'])
    }
  },
  async mounted() {
    await this.init()
  }
}
</script>

<style lang="scss" scope="scope">
.people_dialog {
  width: 60%;
  min-width: 1000px;
  .el-dialog__body {
    margin-top: 30px !important;
  }
  .infinite-list {
    display: flex;
    width: 630px;
    overflow-x: auto;
    overflow-y: hidden;
    .infinite-list-item {
      float: left;
    }
  }
  .box-card {
    position: relative;
    width: 250px;
    height: 120px;
    margin-right: 20px;
    .del-icon {
      position: absolute;
      text-align: center;
      width: 20px;
      height: 20px;
      top: 5px;
      right: 5px;
      z-index: 99;
      cursor: pointer;
    }
    .el-card__body {
      height: 100%;
      padding: 0px !important;
      cursor: pointer;
    }
    .item {
      padding: 20px;
    }
  }
}
</style>
