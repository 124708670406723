<!-- eslint-disable prettier/prettier -->
<template>
  <div id="people-import-page" class="data-import-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <!-- 操作按钮 -->
        <div class="operate-container mb-3">
          <vxPeopleFileXml :loading.sync="loading" :peopleList.sync="peopleList"></vxPeopleFileXml>
        </div>
        <!-- 整体表 -->
        <el-table class="table_theme" :data="peopleList" ref="peopleListTable" v-loading="loading" v-if="reFresh">
          <el-table-column type="index" width="60" label="序号"></el-table-column>
          <el-table-column property="name" label="姓名" width="80" show-overflow-tooltip></el-table-column>
          <el-table-column property="phone" label="手机号" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ $cFun.encryptPhone(scope.row.phone.toString()) }}</p>
            </template>
          </el-table-column>
          <el-table-column property="documentType" label="证件类型" show-overflow-tooltip></el-table-column>
          <el-table-column property="identificationNumber" label="证件号码" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>
                {{ $cFun.encryptIDNumber(scope.row.identificationNumber, 1) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column property="certificateValidity" label="证件有效期" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column property="device_code" label="籍贯" show-overflow-tooltip></el-table-column> -->
          <el-table-column fixed="right" label="操作" width="80" header-align="center">
            <template slot-scope="scope">
              <div v-if="!scope.row.isBind">
                <el-button type="text" size="small" class="primary" @click="openDialog(scope.row, scope.$index)">绑定房屋</el-button>
              </div>
              <div v-else>
                <el-button type="text" size="small" class="primary" disabled>已绑定</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </vx-card>
    <peopleImportDialog :dialogFormData="dialogFormData" :dialogFormVisible="visible" @handleDiaLogClose="close"></peopleImportDialog>
  </div>
</template>

<script>
import vxPeopleFileXml from '@/components/VxPeopleFileXml'
import peopleImportDialog from './people_import_dialog'

export default {
  components: {
    vxPeopleFileXml,
    peopleImportDialog
  },
  data() {
    return {
      // 加载状态
      loading: false,
      reFresh: true,
      peopleList: [],
      dialogFormData: {},
      visible: false
    }
  },
  watch: {
    loading: {
      handler(val) {
        // 实现loading状态下，表格数据清空
        if (!val) this.peopleList = []
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    openDialog(row, currentIndex) {
      if (row.identificationNumber) {
        this.visible = true
        this.dialogFormData = Object.assign({ index: currentIndex }, row)
      } else {
        this.$message({
          message: '请先填写人员,稍后打开',
          type: 'warning',
          duration: 1500,
          offset: 100
        })
      }
    },
    close(val) {
      this.reFresh = false
      this.peopleList[val.index].isBind = val.isBind
      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true
      })
      this.visible = false
    }
  },
  async created() {}
}
</script>

<style lang="scss" scope="scope">
#people-import-page {
  .search-row {
    margin-bottom: 5px;
    .search-select {
      margin-right: 5px;
    }
    .search-input {
      float: right;
      width: 200px;
      margin-right: 5px;
    }
    .search-btn {
      float: right;
      &:hover {
        background-color: rgba(var(--vs-primary), 0.1);
        border-color: rgba(var(--vs-primary), 0.1);
      }
    }
  }
  .table_theme {
    .el-table__expanded-cell[class*='cell'] {
      padding: 0px;
    }
    .el-table__body tr {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .table_height_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
  }
  .el-button.is-disabled.el-button--text {
    text-decoration: line-through;
  }
  .cardListDrawer {
    top: 60px;
    .el-drawer__header {
      margin-bottom: 0px;
    }
    .box-card {
      margin-bottom: 10px;
      .item {
        position: relative;
        height: 100px;
        .bottom {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
